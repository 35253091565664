'use strict';

window.addEventListener('DOMContentLoaded', () => {
  const navButton = document.querySelector(
    '.js-navButton[data-state]'
  ) as HTMLElement;
  const navButtonText = document.querySelector(
    '.js-navButtonText'
  ) as HTMLElement;

  const navItemLink = document.querySelectorAll(
    '.l-heder__navList a[href^="#"]'
  );

  //ページ内リンク時、State変更
  [...navItemLink].forEach((el) => {
    el.addEventListener('click', () => {
      const navState = document.body.dataset.state!;
      const navButtonState: string = navButton.dataset.state!;
      if (navState != 'close') {
        document.body.dataset.state = 'close';
        navButton.dataset.state = 'close';
        changeText(navButtonState);
        return;
      }
    });
  });

  function changeText(el: string) {
    if (el != 'open') {
      navButtonText.textContent = 'close';
      return;
    }
    navButtonText.textContent = 'menu';
  }

  navButton?.addEventListener('click', () => {
    const navButtonState: string = navButton.dataset.state!;
    // data-stateの状態をチェック
    if (navButtonState != 'open') {
      navButton.dataset.state = 'open';
      document.body.dataset.state = 'open';
      changeText(navButtonState);
      return;
    }
    navButton.dataset.state = 'close';
    document.body.dataset.state = 'close';
    changeText(navButtonState);
  });
});
