'use strict';

window.addEventListener('load', () => {
  const headerDom = document.querySelector('.l-header__container')!;

  function setCssProperty() {
    // headerの高さを取得
    const headerDomHeight = headerDom.clientHeight.toString();
    document.documentElement.style.setProperty(
      '--headerHeight',
      `${headerDomHeight}px`
    );
  }
  setCssProperty();
  window.addEventListener('resize', setCssProperty);
});
